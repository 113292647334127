import React, { useState, useEffect, useRef } from "react"
import AireCalculator from "../../tools/aire-calculator/index"
import * as styles from "../../styles/tools.module.css"
import Footer from "../../components/footer"
import { scrollTo } from "../../utils/index"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import PopUpModule from "../../tools/aire-calculator/modules/PopUpModuleAON"

function Index() {
  const [aireAONCampaignRunning, setaireAONCampaignRunning] = useState(false)
  const [Aire, setAire] = useState(false)
  const [url, setUrl] = useState("")

  useEffect(() => {
    setUrl(window?.location.href)
  }, [])

  const startAire = () => {
    setAire(true)
  }

  const myRef = useRef(null)
  const aireRef = useRef(null)

  const seo = (source = "default") => {
    return (
      <>
        {source == "default" ? (
          <SEO
            title={
              "AIRᵉ Calculator- A Self Audit Tool to assess your Employee Recognition Program."
            }
            description={
              "Get an overall analysis of your company's Recognition Program to make it more effective."
            }
          />
        ) : (
          <SEO
            title={
              "AIRᵉ Calculator- A Self Audit Tool to assess your Employee Recognition Program."
            }
            description={
              "Get an overall analysis of your company's Recognition Program to make it more effective."
            }
            meta={[
              {
                name: `robots`,
                content: `index`,
              },
            ]}
          />
        )}
      </>
    )
  }

  const renderLandingPage = () => {
    return (
      <>
        {seo("shrm")}
        <Helmet>
          <script
            type="text/javascript"
            id="hs-script-loader"
            defer
            src="//js.hs-scripts.com/6153052.js"
          ></script>
        </Helmet>

        <div ref={aireRef}>
          <header className="fixed bg-white w-screen top-0 shadow-darkgray">
            <div className="md:container md:mx-auto">
              <div>
                <img
                  className="h-12 md:h-20 w-auto mx-auto"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1677736290/gatsbycms/uploads/2024/03/VC-AON-logo.png"
                  alt="Vantage Circle SHRM Logo"
                />
              </div>
            </div>
          </header>
          <section>
            <div
              className={`${styles.backgroundCoverImgHrms} md:p-20 p-2 px-4 text-center w-100`}
            >
              <div className="md:container md:flex justify-between pt-8 pb-8 md:pb-0 gap-10">
                <div className="md:pt-10 pb-8 md:pb-0 pt-8 md:w-1/2">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2022/02/AIRe_Logo_-1.png"
                    alt="AIRe Logo"
                    className="md:h-28 h-16 md:mx-0 mx-auto"
                  />
                  <div
                    className={`text-3xl md:text-6xl text-white md:mt-4 md:text-left text-center md:leading-10 + ${styles.headingShrm}`}
                  >
                    <span className="text-white ">Annual Employee Rewards & Recognition Program Assessment</span>{" "}
                    <p className="text-white pt-5">Powered by Vantage Circle's Proprietary AIR<sup>e</sup> Framework</p>
                    {/* <br />
                    Insights through
                    <br />
                    AIRᵉ Framework */}
                  </div>
                </div>
                <div className="md:pt-10 md:w-1/2">
                  {
                    aireAONCampaignRunning ? <PopUpModule startAire={startAire} /> 
                    :
                    <div className="h-full flex place-content-center">
                      <h2 className="m-auto py-10 px-5 bg-white rounded-lg">The Annual R&R Assessment for 2024-25 is closed as of now. Stay tuned for the upcoming report. If you have any query, kindly drop a message in <a href="/contact-us/" className="text-orange">Contact Us</a></h2>
                    </div>
                  }
            
                </div>
              </div>
            </div>
          </section>
          <section className="flex flex-col w-10/12 mx-auto md:flex-row py-10 xl:py-16 bg-red-300">
            <div className="md:p-2 p-4 md:text-2xl">
              <h4 ref={myRef} className="md:mt-0">
                The DIY audit provides an overall assessment of your company's
                existing recognition program and
              </h4>
              <ul className="md:pt-1 py-3 md:py-0">
                <li className="my-3 md:my-12 text-lg xl:text-2xl">
                  <span className="text-orange">&#10004;</span> Get a detailed R&R Program Benchmarking report powered by the AIR<sup>e</sup> framework.
                </li>
                <li className="my-3 md:my-12 text-lg xl:text-2xl">
                  <span className="text-orange">&#10004;</span> Identifying areas that can be worked upon on R&R Programs.{" "}
                </li>
              </ul>
              <h4 className="pt-4 md:my-12">
                Respond to the questions based on your current R&R strategy to
                receive a data-backed quality score that compares to global
                recognition benchmarks.
              </h4>
              <div className="flex justify-center lg:justify-start text-center lg:text-left w-full my-7">
                <div className="cursor-pointer md:w-4/6 xl:w-1/3 flex justify-center lg:justify-start whitespace-nowrap">
                  <a
                    className="p-5 rounded text-white bg-purple-500"
                    // onClick={onClickPopupHandler}
                    onClick={() => scrollTo(aireRef)}
                    id="aire-cal-link"
                  >
                    Start your AIRᵉ Assessment
                  </a>
                </div>
              </div>
            </div>
            <img
              className={styles.emotionalImg}
              src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995802/gatsbycms/uploads/2022/02/Emotional_connect.png"
            ></img>
          </section>
          <section className="w-10/12 mx-auto flex flex-col place-items-center">
            <div className="w-full lg:w-4/6 flex place-content-center">
              <h2 className="text-3xl md:text-4xl lg:text-6xl text-gray-500 mt-7 mb-3 text-center">
                Why&nbsp;
                <span className="text-purple-200">AIRᵉ Framework?</span>
              </h2>
            </div>
            <p className="text-center w-11/12 md:4/6 xl:w-1/2 mt-3 text-xl">
              The AIRᵉ Framework is a powerful tool that gives companies a
              baseline for creating a strong foundation for your recognition
              program. Let your workforce breathe upon and thrive the 'AIRᵉ'
              they need.
            </p>
            <div className="w-9/12 xl:w-11/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-16">
              <div className="p-3 px-5 rounded-lg bg-gray-100 border-white border-2 shadow-lg flex flex-col place-items-center">
                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995801/gatsbycms/uploads/2022/02/Adopt_a_holistic_structured_approach.png" />
                <p className="text-center my-4">
                  Adopt a holistic & structured approach
                </p>
              </div>
              <div className="p-3 px-5 rounded-lg bg-gray-100 border-white border-2 shadow-lg flex flex-col place-items-center">
                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995802/gatsbycms/uploads/2022/02/Instill_a_Culture_of_Recognition.png" />
                <p className="text-center my-4">
                  Instill a Culture of Recognition
                </p>
              </div>
              <div className="p-3 px-5 rounded-lg bg-gray-100 border-white border-2 shadow-lg flex flex-col place-items-center">
                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995801/gatsbycms/uploads/2022/02/Cater_to_Your_Hybrid_Workforce.png" />
                <p className="text-center my-4">
                  Cater to Your Hybrid Workforce
                </p>
              </div>
              <div className="p-3 px-5 rounded-lg bg-gray-100 border-white border-2 shadow-lg flex flex-col place-items-center">
                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995801/gatsbycms/uploads/2022/02/Assess_With_Data-Backed_Analytics.png" />
                <p className="text-center my-4">
                  Assess With Data-Backed Analytics
                </p>
              </div>
            </div>
          </section>
        </div>
        <section className="w-full flex flex-col place-items-center bg-purple-200 p-5 lg:p-10 mt-8">
          <h2 className="text-center text-purple-100 text-2xl md:text-4xl container">
            "Want to know how the AIRᵉ Framework can be utilized to make your
            recognition programs more effective?"
          </h2>
          <button className="rounded w-4/6 md:w-2/6 lg:w-2/6 xl:w-1/6 mt-6 flex items-center justify-center  py-2 border border-transparent text-sm mb-3 md:text-base text-purple-300 bg-white md:text-lg transition duration-500 ease-in-out transform hover:shadow hover:-translate-y-1">
            <a
              href="https://www.vantagecircle.com/hr-academy/whitepapers/aire-framework/"
              rel="alternate"
              target="_blank"
            >
              Download Whitepaper
            </a>
          </button>
        </section>
        <Footer slantedTop={false} />
      </>
    )
  }

  const renderTool = () => {
    return (
      <>
        {seo("shrm")}
        <AireCalculator source="aon-aire" url={url} />
      </>
    )
  }
  return <>{Aire == true ? renderTool() : renderLandingPage()}</>
}

export default Index
